import './scss/main.scss'
import '@fontsource/fira-sans/300.css';
import '@fontsource/fira-sans/400.css';
import '@fontsource/fira-sans/500.css';
import '@fontsource/fira-sans/700.css';
import '@fontsource/fira-sans/900.css';
import '@fontsource-variable/inter';

import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ErrorBoundary from './components/ErrorBoundary';
import { TooltipProvider } from './components/ui/tooltip';

if (import.meta.env.MODE == 'production') {
    Bugsnag.start({
        apiKey: 'a699cf489b193465f7ebef1c5e49d974',
        plugins: [new BugsnagPluginReact()],
    })
}

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('app')).render(
    <ErrorBoundary>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <TooltipProvider>
                    <ReactQueryDevtools initialIsOpen={false} buttonPosition='bottom-left' />
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </TooltipProvider>
            </QueryClientProvider>
        </BrowserRouter>
    </ErrorBoundary>
)