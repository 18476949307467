import axios from "axios";
import { useAuth } from "./useAuth";

const ELASTIC_BASE = import.meta.env.VITE_ELASTIC_BASE
    ? import.meta.env.VITE_ELASTIC_BASE
    : import.meta.env.VITE_API_BASE + '/elastic';

const useElastic = () => {
    const { publishers, user } = useAuth();

    const search = async ({ index, body }) => {
        const result = await axios.post(`${ ELASTIC_BASE }/${ index }/_search`, body, {
            headers: {
                "Content-Type": "application/json"
            },
            auth: {
                username: "elastic",
                password: "westarp"
            }
        });

        return result.data;
    };

    const count = async ({ index, body }) => {
        const result = await axios.post(`${ ELASTIC_BASE }/${ index }/_count`, 
            {...body, sort: undefined, aggs: undefined, size: undefined, from: undefined}, 
            {
                headers: {
                    "Content-Type": "application/json"
                },
                auth: {
                    username: "elastic",
                    password: "westarp"
                }
            }
        );

        return result.data;
    };

    const searchBooks = (term: string, limit?: number, availableOnly: boolean = false) => {
        const query = {
            bool: {
                must: [],
                should: term.split(' ').map(word => [
                    { query_string: { query: word } },
                    // { term: { _id: { value: word, boost: 10 } } },
                    { match: { article_number: { query: word, boost: 5 } } },
                    { wildcard: { isbn13: { value: "*" + word.replace(/[^0-9]/g, '') + "*", boost: 10 } } },
                    { wildcard: { isbn10: { value: "*" + word.replace(/[^0-9]/g, '') + "*", boost: 10 } } },
                    { wildcard: { authors: "*" + word + "*" } },
                    { term: { status: { value: 'available', boost: 0.5 } } }
                ]).flat(),
                filter: [
                    // { terms: { status: ['available', 'planned', 'non_web', 'pending'] } }
                ]
                    
            }
        };

        if (availableOnly) {
            // query.bool.must.push({ terms: { status: ['available', 'planned', 'non_web', 'pending'] } });
        }

        query.bool.must.push({ terms: { publisher_id: publishers.map(publisher => publisher.id) } });

        return search({
            index: ['books', 'bundles'],
            body: {
                min_score: 1,
                size: limit || 10,
                query,
                sort: [
                    { _score: { order: 'desc' } },
                ]
            }
        });
    };

    const searchPersons = (term, extraIncludedPersonTypes = []) => {
        const body: any = {
            min_score: 1,
            query: {
                bool: {
                    should: term.split(' ').map(word => [
                        { query_string: { query: word } },
                        { term: { _id: { value: word, boost: 10 } } },
                        { term: { verkehrsnummer: word } },
                        { fuzzy: { company: word }},
                        { fuzzy: { firstname: { value: word } } },
                        { fuzzy: { lastname: { value: word } } }
                    ]).flat(),
                    filter: [
                        {
                            bool: {
                                minimum_should_match: 1,
                                should: [
                                    { terms: { publisher_id: user.publishers.map(p => p.id) } },
                                ]
                            }
                        }
                    ],
                },
            }
        };
        if (extraIncludedPersonTypes.length > 0) {
            body.query.bool.filter[0].bool.should.push({terms: {types: extraIncludedPersonTypes}})
        }
        return search({
            index: 'persons',
            body
        });
    }

    const searchAll = async (index:string, body: any, total: number, onProgress = (_: number) => {} ) => {
        body = {...body, size: undefined, from: undefined};

        onProgress(0);
        
        const MAX_RESULT_SIZE = 10000;
        body.size = Math.min(MAX_RESULT_SIZE, total);

        const entries = [];
        let cursor = undefined;
        let remaining = total;
        while (remaining > 0) {
            body.search_after = cursor;
            body.size = remaining > MAX_RESULT_SIZE ? MAX_RESULT_SIZE : remaining;

            const result = await search({index, body });
            
            const hits = result.hits.hits.map(hit => ({
                id: hit._id,
                ...hit._source
            })) as any[];

            entries.push(...hits)
            
            cursor = result.hits.hits[result.hits.hits.length-1].sort;
            remaining -= Math.min(MAX_RESULT_SIZE, remaining);
            onProgress(entries.length / total * 100);
        }
        onProgress(100);
        
        return entries;
    }

    return {
        searchBooks,
        searchPersons,
        search,
        count,
        searchAll
    }
};

export default useElastic;